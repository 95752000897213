export default {
    visible: {
        viewbox: "0 0 24 18",
        path: ['M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z','M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z']
    },
    visible_off: {
        viewbox: "0 0 24 24",
        path: ['M14.12 14.12C13.8454 14.4147 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1962C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4859 9.58525 10.1546 9.88 9.88M1 1L23 23M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.6819 3.96914 7.65661 6.06 6.06L17.94 17.94ZM9.9 4.24C10.5883 4.07888 11.2931 3.99834 12 4C19 4 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.24Z']
    },
    applications: {
        viewbox: "0 0 20 20",
        path: ["M8 1H1V8H8V1Z", "M19 1H12V8H19V1Z", "M19 12H12V19H19V12Z", "M8 12H1V19H8V12Z"]
    },
    lock: {
        viewbox: "0 0 20 22",
        path: ["M5.41667 10V6C5.41667 4.67392 5.89955 3.40215 6.75909 2.46447C7.61864 1.52678 8.78442 1 10 1C11.2156 1 12.3814 1.52678 13.2409 2.46447C14.1004 3.40215 14.5833 4.67392 14.5833 6V10M3.58333 10H16.4167C17.4292 10 18.25 10.8954 18.25 12V19C18.25 20.1046 17.4292 21 16.4167 21H3.58333C2.57081 21 1.75 20.1046 1.75 19V12C1.75 10.8954 2.57081 10 3.58333 10Z"]
    },
    user: {
        viewbox: "0 0 18 20",
        path: ["M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19M13 5C13 7.20914 11.2091 9 9 9C6.79086 9 5 7.20914 5 5C5 2.79086 6.79086 1 9 1C11.2091 1 13 2.79086 13 5Z"]
    },
    alert: {
        viewbox: "0 0 23 21",
        path: ["M11.448 7.10274V11.1027M11.448 15.1027H11.458M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"]
    },
    trash: {
        viewbox: "0 0 20 22",
        path: ['M1 5H3M3 5H19M3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V5H3ZM6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M8 10V16M12 10V16']
    },
    access: {
        viewbox: "0 0 22 22",
        path: ["M5 5H5.01M5 17H5.01M3 1H19C20.1046 1 21 1.89543 21 3V7C21 8.10457 20.1046 9 19 9H3C1.89543 9 1 8.10457 1 7V3C1 1.89543 1.89543 1 3 1ZM3 13H19C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H3C1.89543 21 1 20.1046 1 19V15C1 13.8954 1.89543 13 3 13Z"]
    },
    server: {
        viewbox: '0 0 20 20',
        path: ['M16 1C15.2044 1 14.4413 1.31607 13.8787 1.87868C13.3161 2.44129 13 3.20435 13 4V16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13H4C3.20435 13 2.44129 13.3161 1.87868 13.8787C1.31607 14.4413 1 15.2044 1 16C1 16.7956 1.31607 17.5587 1.87868 18.1213C2.44129 18.6839 3.20435 19 4 19C4.79565 19 5.55871 18.6839 6.12132 18.1213C6.68393 17.5587 7 16.7956 7 16V4C7 3.20435 6.68393 2.44129 6.12132 1.87868C5.55871 1.31607 4.79565 1 4 1C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4C1 4.79565 1.31607 5.55871 1.87868 6.12132C2.44129 6.68393 3.20435 7 4 7H16C16.7956 7 17.5587 6.68393 18.1213 6.12132C18.6839 5.55871 19 4.79565 19 4C19 3.20435 18.6839 2.44129 18.1213 1.87868C17.5587 1.31607 16.7956 1 16 1Z']
    },
    edit: {
        viewbox: "0 0 23 23",
        path: ["M10 3.12132H3C2.46957 3.12132 1.96086 3.33203 1.58579 3.70711C1.21071 4.08218 1 4.59089 1 5.12132V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5355C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5355C18.7893 20.1605 19 19.6518 19 19.1213V12.1213M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z"]
    },
    close: {
        viewbox: "0 0 14 14",
        path: ["M13 1L1 13M1 1L13 13"]
    },
    repeat: {
        viewbox: "0 0 20 24",
        path: ["M15 1L19 5M19 5L15 9M19 5H5C3.93913 5 2.92172 5.42143 2.17157 6.17157C1.42143 6.92172 1 7.93913 1 9V11M5 23L1 19M1 19L5 15M1 19H15C16.0609 19 17.0783 18.5786 17.8284 17.8284C18.5786 17.0783 19 16.0609 19 15V13"]
    },
    caret_left: {
        viewbox: '0 0 8 14',
        path: ['M7 1L1 7L7 13']
    },
    caret_down: {
        viewbox: "0 0 14 8",
        path: ["M1 1L7 7L13 1"]
    },
    caret_up: {
        viewbox: "0 0 14 8",
        path: ["M13 7L7 1L1 7"]
    },
    phone: {
        viewbox: "0 0 16 22",
        path: ["M8 17H8.01M3 1H13C14.1046 1 15 1.89543 15 3V19C15 20.1046 14.1046 21 13 21H3C1.89543 21 1 20.1046 1 19V3C1 1.89543 1.89543 1 3 1Z"]
    },
    clipboard: {
        viewbox: "0 0 18 22",
        path: ['M13 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H5M6 1H12C12.5523 1 13 1.44772 13 2V4C13 4.55228 12.5523 5 12 5H6C5.44772 5 5 4.55228 5 4V2C5 1.44772 5.44772 1 6 1Z']
    },
    file: {
        viewbox: '0 0 18 22',
        path: ['M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7M11 1L17 7M11 1V7H17M13 12H5M13 16H5M7 8H5']
    },
    terminal: {
        viewbox: '0 0 18 16',
        path: ['M1 13L7 7L1 1M9 15H17']
    },
    info: {
        viewbox: "0 0 22 22",
        path: ["M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"]
    },
    menu: {
        viewbox: "0 0 20 14",
        path: ['M1 7H19M1 1H19M1 13H19']
    },
    upload: {
        viewbox: "0 0 20 20",
        path: ["M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13M15 6L10 1M10 1L5 6M10 1V13"]
    },
    connections: {
        viewbox: "0 0 20 22",
        path: ["M6.59 12.51L13.42 16.49M13.41 5.51L6.59 9.49M19 4C19 5.65685 17.6569 7 16 7C14.3431 7 13 5.65685 13 4C13 2.34315 14.3431 1 16 1C17.6569 1 19 2.34315 19 4ZM7 11C7 12.6569 5.65685 14 4 14C2.34315 14 1 12.6569 1 11C1 9.34315 2.34315 8 4 8C5.65685 8 7 9.34315 7 11ZM19 18C19 19.6569 17.6569 21 16 21C14.3431 21 13 19.6569 13 18C13 16.3431 14.3431 15 16 15C17.6569 15 19 16.3431 19 18Z"]
    },
    loader: {
        viewbox: "0 0 22 22",
        path: ["M11 1V5M11 17V21M3.93 3.93L6.76 6.76M15.24 15.24L18.07 18.07M1 11H5M17 11H21M3.93 18.07L6.76 15.24M15.24 6.76L18.07 3.93"]
    },
    copy: {
        viewbox: "0 0 22 22",
        path: ["M4 14H3C2.46957 14 1.96086 13.7893 1.58579 13.4142C1.21071 13.0391 1 12.5304 1 12V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V4M10 8H19C20.1046 8 21 8.89543 21 10V19C21 20.1046 20.1046 21 19 21H10C8.89543 21 8 20.1046 8 19V10C8 8.89543 8.89543 8 10 8Z"]
    },
    download: {
        viewbox: "0 0 20 20",
        path: ["M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13M5 8L10 13M10 13L15 8M10 13V1"]
    }
}

