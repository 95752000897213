import Vue from 'vue';
import router from '@/router';
import App from '@/App.vue';
import ErrorList from '@/components/ErrorList.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import BaseAlert from '@/components/BaseAlert.vue';
import themeService from '@/services/themeService.js';
import VueCountdown from '@chenfengyuan/vue-countdown/dist/vue-countdown.esm.js';
import axios from 'axios';
import VueSnackbar from 'vue-snack';
import {getResponseMessage, getCookie, mfaServiceErrors} from '@/utils/index.js';
import Modals from '@/utils/modalPlugin';

Vue.use(Modals, router);

Vue.prototype.$baseLink = window.rmsBasePath ? window.rmsBasePath : '/';
Vue.prototype.$platform = window.platform ? window.platform : null;
Vue.prototype.$http = axios.create({
    baseURL: Vue.prototype.$baseLink,
    headers: { 'X-Requested-With': 'XMLHttpRequest'}
});

Vue.prototype.$mfaServiceErrors = mfaServiceErrors;
Vue.prototype.$authUser = window.authUser;
Vue.prototype.$http.defaults.xsrfCookieName = getCookie('RMS-XSRF-TOKEN')
    ? 'RMS-XSRF-TOKEN'
    : 'XSRF-TOKEN';

Vue.prototype.$http.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

Vue.prototype.$eventHub = new Vue({});

Vue.prototype.$theme = themeService;

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';

Vue.component('ErrorList', ErrorList);
Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseAlert', BaseAlert);
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueSnackbar, { position: 'top-right', time: 3000, close: true });
Vue.prototype.$resMsg = getResponseMessage;
Vue.prototype.$userData = null;
new Vue({
    router,
    render: h => h(App),
}).$mount("#app");

Vue.prototype.$validate =  function(string) {
    return !!string.match(new RegExp('://'));
}

Vue.prototype.$isDev = process.env.NODE_ENV === 'development';