<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="base-icon"
    :class="[$attrs.class, name]"
    :viewBox="getViewbox(name)" 
    :height="size" 
    :width="size" 
    >
        <title v-text="$attrs.title"></title>
        <path v-for="(path, $index) in getPath(name)" :key="$index" :d="path" :stroke="color" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
import icons from '@/utils/iconDefs.js'

export default {
    props: {
        name: {
            required: true,
            type: String
        },
        color: {
            type: String,
            default: 'currentColor'
        },
        size: {
            type: [String, Number],
            default: "1em"
        }
    },
    methods: {
        getPath(name){
            return icons[name].path
        },
        getViewbox(name){
            return icons[name].viewbox
        }
    }
}
</script>

<style lang="scss" scoped>
    .active{
        color: var(--mainColor);
    }
</style>
