import VueRouter from 'vue-router'
import Vue from 'vue'
import { externalDisabledRoutes } from '@/utils'

const baseLink = window.rmsBasePath;

Vue.use(VueRouter)
const router = new VueRouter({
    linkExactActiveClass: 'link-active',
    routes: [
        // Auth
        {
            path: '',
            component: () => import('@/views/AuthLayout.vue' /* webpackChunkName: "login" */),
            children: [
                {
                    name: 'auth.login',
                    path: '/',
                    component: () => import('@/views/Login.vue' /* webpackChunkName: "login" */)
                },
                {
                    name: 'auth.sign-in-with-sso',
                    path: '/sign-in-with-sso',
                    component: () => import('@/views/LoginSSO.vue' /* webpackChunkName: "login" */)
                },
                {
                    name: 'auth.register',
                    path: '/register',
                    component: () => import('@/views/Register.vue' /* webpackChunkName: "login" */)
                },
                {
                    name: 'auth.invite',
                    path: '/email-invite',
                    component: () => import('@/views/EmailInvite.vue' /* webpackChunkName: "login" */)
                },
                {
                    name: 'auth.forgot-pass',
                    path: '/forgot-password',
                    component: () => import('@/views/ForgotPassword.vue' /* webpackChunkName: "login" */)
                },
                {
                    name: 'auth.reset-pass',
                    path: '/reset-password/:token',
                    component: () => import('@/views/ResetPassword.vue' /* webpackChunkName: "login" */)
                },
                {
                    name: 'auth.activate',
                    path: '/activate/:token',
                    component: () => import('@/views/ActivateUser.vue' /* webpackChunkName: "login" */)
                } 
            ]
        },
        {
            name: 'change-email',
            path: '/change-email/:token',
            component: () => import('@/views/ChangeEmail.vue' /* webpackChunkName: "email" */)
        },
        {
            name: 'login.redirect',
            path: '/login/redirect',
            component: () => import('@/views/Redirect.vue' /* webpackChunkName: "redirect" */),
            meta: {
                requiresAuth: true,
            }
        },
        // Settings
        {
            path: "",
            component: () => import('@/views/SettingsLayout.vue' /* webpackChunkName: "settings" */),
            children: [
                {
                    path: '/settings/mfa-required',
                    name: 'settings.mfa-required',
                    component: () => import('@/views/MFARequired.vue' /* webpackChunkName: "settings" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/profile',
                    name: 'settings.profile',
                    component: () => import('@/views/Profile.vue' /* webpackChunkName: "profile" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/security',
                    name: 'settings.security',
                    component: () => import('@/views/Security.vue' /* webpackChunkName: "security" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/connections',
                    name: 'settings.connections',
                    component: () => import('@/views/Connections.vue' /* webpackChunkName: "connections" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/applications',
                    name: 'settings.applications',
                    component: () => import('@/views/Applications.vue' /* webpackChunkName: "applications" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/application',
                    name: 'settings.applications.create',
                    component: () => import('@/views/ApplicationForm.vue' /* webpackChunkName: "applications" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/application/:id',
                    name: 'settings.applications.edit',
                    component: () => import('@/views/ApplicationForm.vue' /* webpackChunkName: "applications" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/authorized-applications',
                    name: 'settings.authorized',
                    component: () => import('@/views/Autorized.vue' /* webpackChunkName: "autorized" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/tokens',
                    name: 'settings.tokens',
                    component: () => import('@/views/Tokens.vue' /* webpackChunkName: "tokens" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/token/',
                    name: 'settings.tokens.create',
                    component: () => import('@/views/TokenForm.vue' /* webpackChunkName: "tokens" */),
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: '/settings/token/:id',
                    name: 'settings.tokens.edit',
                    component: () => import('@/views/TokenForm.vue' /* webpackChunkName: "tokens" */),
                    meta: {
                        requiresAuth: true,
                    }
                }
            ]
        },
        // Policy layout
        {
            path: '',
            component: () => import('@/views/PolicyLayout.vue' /* webpackChunkName: "policy" */),
            children: [
                {
                    name: 'privacy-policy',
                    path: '/privacy-policy',
                    component: () => import('@/views/PrivacyPolicy.vue' /* webpackChunkName: "policy" */),
                    meta: {isPolicy: true}
                },
                {
                    name: 'terms-of-service',
                    path: '/terms-of-service',
                    component: () => import('@/views/TermsOfService.vue' /* webpackChunkName: "policy" */),
                    meta: {isPolicy: true}
                },
                {
                    name: 'data-agreement',
                    path: '/data-processing-agreement',
                    component: () => import('@/views/DataAgreement.vue' /* webpackChunkName: "policy" */),
                    meta: {isPolicy: true}
                }
            ]
        },
        // Social/SSO Callback
        {
            name: 'social.callback',
            path: '/login/social/:provider/callback',
            component: () => import('@/views/Callback.vue' /* webpackChunkName: "login" */)
        },
        {
            name: 'sso.callback',
            path: '/sign-in/sso/callback',
            component: () => import('@/views/Callback.vue' /* webpackChunkName: "login" */)
        },
        // Fallback route
        {
            path: '*',
            redirect: '/' 
        }
    ],
    base: baseLink ? baseLink : '/', 
    mode: 'history',
});

async function userData(){
    if (Vue.prototype.$userData) return Vue.prototype.$userData;
    const userFetch = await fetch(`${baseLink ? baseLink : ''}/user`, { method: 'GET', headers: { 'X-Requested-With': 'XMLHttpRequest' } })
    const userData = await userFetch.json();
    return userData;
}
  
router.beforeEach(async (to, from, next) => {
    if(to.meta.isPolicy){
        if(process.env.NODE_ENV === 'production'){
            return next();
        }
        else {
            return next({ path: '/' });
        }
    }
    else if (to.matched.some(route => route.meta.requiresAuth)) {
        Vue.prototype.$userData = await userData();
        if(Vue.prototype.$userData){
            if(Vue.prototype.$userData?.external){
                const isRouteDisabled = externalDisabledRoutes?.some(route => {return to.name.includes(route)});
                if(isRouteDisabled) return next({ path: '/settings/profile' });
            }
            if(Vue.prototype.$theme.auth_disabled_routes){
                const isRouteDisabled = Vue.prototype.$theme.auth_disabled_routes.some(route => {return to.name.includes(route)});
                if(isRouteDisabled) return next({ path: '/settings/profile' });
                else return next();
            }
        }
        else{
            return next({ path: '/' });
        }
    }
    if(to?.name == "auth.register" && to?.query?.platform == 'mobile-review'){
        return next({ path: '/', query: to.query });
    }
    return next();
});
 
export default router;