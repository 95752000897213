<template>
    <transition name="slide" appear>
        <ul class="error-list" v-if="Array.isArray(errors)">
            <li v-for="(error, $index) in errors" :key="$index" class="field-error" v-html="error.message">
            </li>
        </ul>
        <p v-else class="field-error" v-html="errors.message"></p>
    </transition>
</template>

<script>
export default {
    props: {
        errors: {
            type: [Array, Object],
            required: true
        }
    }
}
</script>
<style lang="scss">
    .field-error{
        color: #BC5265;
    }
    //slide transition
    .slide-enter-active, .slide-leave-active{
        transition-duration: 0.2s;
    }
    .slide-enter-to, .slide-leave {
        line-height: 1.2;
        overflow: hidden;
    }
    .slide-enter, .slide-leave-to {
        line-height: 0;
        opacity: 0;
        overflow: hidden;
    }
</style>