export const externalDisabledRoutes = ['settings.connections'];

export const passwordRules = [
    {text: 'At least 8 characters', regex: /.{8,}/},
    {text: 'At least one number [0-9]', regex: /[0-9]+/},
    {text: 'At least one lowercase letter [a-z]', regex: /[a-z]+/},
    {text: 'At least one uppercase letter [A-Z]', regex: /[A-Z]+/},
    {text: 'At least one special symbol [#?!@$%^&*-]', regex: /[#?!@$%^&*-]+/}
];

export const mfaServiceErrors = ['MFA_UNAVAILABLE', 'NO_DEVICES_LINKED', 'TELTONIKA_ID_NOT_AVAILABLE'];

export const whitelist = [
    'continue_url',
    'continue_review_id',
    'response_type',
    'client_id',
    'redirect_uri',
    'code_challenge',
    'code_challenge_method', 
    'state',
    'scope',
    'code',
    'platform'
];

const endpointKeys = {
    'generate': 'password',
    'access':'remote access'
}

export function getResponseMessage(res){
    if(!res) return;
    
    const isSuccess = res.data.success;
    const verb = responseVerb(res.config.method);
    const item = responseItem(res.config.url) || 'item';

    if(isSuccess) return `${capitalize(item)} ${verb}d successfully`;
    return `Failed to ${verb} ${item}`
   
}

function responseVerb(method){
    switch (method) {
        case 'put': 
            return 'update';
        case 'delete':
            return 'remove';
        case 'post':
            return 'create';
    }
}

export function showLanguageSelect(){
    let browserLanguages = window?.navigator?.languages?.map(l => l?.toLowerCase()) || [];
    let isSelectAvailable = browserLanguages?.includes('ja') ? true : false;

    if(window?.hasOwnProperty('countryIsoCode') || isSelectAvailable){
        let countryCode = isSelectAvailable ? 'ja' : window['countryIsoCode']?.toLowerCase();

        if(['jp', 'ja'].includes(countryCode)) {
            return true;
        }
    }
    return false;
}

function responseItem(endpoint){
    const stringArray = endpoint.split("?")[0].match(/([a-zA-Z]+)/g);
    let item = stringArray[stringArray.length-1];
    if (endpointKeys[item]) return endpointKeys[item];
    if (item.slice(-3) === 'ies') return item.slice(0, -3)+'y'; 
    if (item.slice(-1) === 's') return item.slice(0, -1); 
    return item;
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : undefined;
}

export function detectIOSUser(){
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
